.pagination-container {
    display: grid;
    place-items: center;
    grid-template-columns: 1fr;
}

.pagination-display {
    display: grid;
    grid-gap: 10%;
    /*min-height: 50vh;*/
    place-items: center;
    padding: 20px;
}

.pagination-controls {
    display: grid;
    grid-template-columns: 1fr 1fr 1fr;
    grid-gap: 10px;
    padding: 40px;
}