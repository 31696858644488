.floating-container {
    z-index: 2;
    width: 100vw;
    top: 0;
    display: flex;
    flex-direction: column;
    justify-content: space-evenly;
    align-items: center;
    position: absolute;
    opacity: 0.2;
}

.floating-slip {
    width: 75vw;
    margin: 20px;
}

.floating-slip-0 {
    transform: translateX(-50px) rotate(-5deg);
}

.floating-slip-1 {
    transform: translateX(0px) rotate(-5deg);
}

.floating-slip-2 {
    transform: translateX(50px) rotate(-5deg);
}

@media only screen and (min-width: 1200px) {
    .floating-slip {
        width: 33vw;
    }

    .floating-container {
        width: 50vw;
        height: 75vh;
        position: relative;
        opacity: 1;
    }
}
