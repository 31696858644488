.header h5 {
    text-align: center;
    margin: 0;
}

.header {
    position: relative;
    padding: 5px;
    border-radius: 10px !important;
}

.header * {
    font-size: 19px;
}

.header p {
    position: absolute;
    top: 50%;
    right: 20px;
    transform: translate(-50%, -50%);
    user-select: none;
}

.bet-card {
    border: 2px solid black;
    border-radius: 10px;
    width: 100%;
}

.betslip_title_container {
    display: grid;
    grid-template-columns: auto;
    place-items: center;
}

.betslip_title {
    display: grid;
    grid-template-columns: auto auto;
    grid-gap: 5px;
    place-items: center;
}

.betslip_title svg {
    height: 66%
}