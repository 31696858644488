.nav {
    position: relative;
    display: flex;
    justify-content: flex-end;
    align-items: center;
    min-height: 5vh;
    background: #563d7c;
    font-weight: bold;
    border-bottom: 2px solid black;
    padding: 30px;
}

.nav span {
    color: white !important;
}

.nav > div:first-child {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%,-50%)
}
