.landing {
    position: relative;
}

@media only screen and (min-width: 1200px) {
    .landing {
        display: grid;
        grid-template-columns: 1fr 1fr;
    }
}
