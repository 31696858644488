.body {
    display: grid;
    grid-template-columns: 1fr 1fr;
    grid-gap: 0px;
    padding: 5px;
}

.body * {
    font-size: 17px;
}

.grid3col {
    display: grid;
    grid-template-columns: 1fr 1fr 1fr;
}

.grid3col > *, .matchup {
    border: 1px solid black;
    margin: 0;
}

.fixture {
    font-weight: bold;
}

.moneyBox {
    padding-top: 10px;
    grid-column: 1 / span 2;
    display: grid;
    grid-template-columns: 1fr;
    background-color: #fff2cc;
    border-radius: 10px;
}

.moneyBox.layBet {
    background-color: #ebffde;
}

.percentages-use-full-width {
    grid-column: 1 / span 2;
    display: grid;
}

.pred_comparison {
    display: grid;
    grid-template-columns: auto auto;
}

.pred_comparison svg {
    justify-self: end;
}

.pred_comparison h5 {
    justify-self: start;
}

.pred_comparison * {
    margin: 0;
}